<template>
  <section>
    <section class="top">
      <div>
        <p class="std_title">
          Student
        </p>
        <p class="std_subtitle">
          Add New Student
        </p>
      </div>
      <div>
        <p class="std_subtitle">
          {{ stdName ? stdName : 'Student Name' }}
        </p>
        <p class="std_subtitle">
          {{ admClass ? `Class: ${admClass}`: 'Student Class' }}
        </p>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          @click="save()"
        >
          Save
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="grid">
        <div>
          <VForm
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Personal
              </p>
              <div class="std_content">
                <div class="span2">
                  <VTextField
                    v-model="stdName"
                    :rules="[rules.required]"
                    :value="stdName"
                    :hide-details="true"
                    label="Name"
                    placeholder="Camila Cabello"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VSelect
                    v-model="stdGender"
                    :rules="[rules.required]"
                    :items="selectGender"
                    :hide-details="true"
                    label="Gender"
                    placeholder="Male / Female"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VSelect
                    v-model="stdCategory"
                    :rules="[rules.required]"
                    :items="selectCategory"
                    :hide-details="true"
                    label="Category"
                    placeholder="General / SC"
                    class="std_main_txt"
                  />
                </div>
                <!-- Date Of Birth -->
                <div>
                  <VDialog
                    ref="stdDobModal"
                    v-model="dobModal"
                    :return-value.sync="stdDobDef"
                    lazy
                    full-width
                    persistent
                    width="290"
                  >
                    <VTextField
                      slot="activator"
                      :rules="[rules.required]"
                      :value="stdDob"
                      :hide-details="true"
                      label="Date Of Birth"
                      placeholder="01-12-1990"
                      class="std_main_txt"
                      readonly
                    />
                    <VCard class="elevation-20 picker_card">
                      <VDatePicker
                        ref="picker"
                        v-model="stdDobDef"
                        :max="new Date().toISOString().substr(0, 10)"
                        color="primary"
                        no-title
                        scrollable
                        class="elevation-0 pt-2"
                        min="1990-01-01"
                      />
                      <VCardActions>
                        <VSpacer />
                        <div class="pt-2 pr-2 pl-0 pb-2">
                          <VBtn
                            class="ma-0"
                            color="primary"
                            flat
                            round
                            depressed
                            @click="dobModal = false"
                          >
                            Close
                          </VBtn>
                          <VBtn
                            class="ma-0"
                            color="primary"
                            flat
                            round
                            depressed
                            @click="$refs.stdDobModal.save(stdDobDef)"
                          >
                            Save
                          </VBtn>
                        </div>
                      </VCardActions>
                    </VCard>
                  </VDialog>
                </div>
                <!-- Date Of Birth END-->
              </div>
            </VCard>
          </VForm>
        </div>
        <div>
          <VForm
            ref="form1"
            v-model="valid"
            lazy-validation
          >
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Admission
              </p>
              <div class="std_content">
                <div>
                  <div>
                    <VTextField
                      v-model="admNoInput"
                      :rules="[rules.required]"
                      :mask="admNoFormat"
                      :hide-details="true"
                      prefix="FLA"
                      placeholder="000-1920"
                      class="std_main_txt"
                      label="Admission Number"
                    />
                  </div>
                </div>
                <!-- Date Picker START -->
                <div>
                  <VDialog
                    ref="admDateModal"
                    v-model="admModal"
                    :return-value.sync="admDateDef"
                    lazy
                    full-width
                    persistent
                    width="290"
                  >
                    <VTextField
                      slot="activator"
                      :value="admDate"
                      :hide-details="true"
                      label="Admission Date"
                      class="std_main_txt"
                      readonly
                    />
                    <VCard class="elevation-20 picker_card">
                      <VDatePicker
                        v-model="admDateDef"
                        color="primary"
                        no-title
                        scrollable
                        class="elevation-0 pt-2"
                      />
                      <VCardActions>
                        <VSpacer />
                        <div class="pt-2 pr-2 pl-0 pb-2">
                          <VBtn
                            class="ma-0"
                            color="primary"
                            flat
                            round
                            depressed
                            @click="admModal = false"
                          >
                            Close
                          </VBtn>
                          <VBtn
                            class="ma-0"
                            color="primary"
                            flat
                            round
                            depressed
                            @click="$refs.admDateModal.save(admDateDef)"
                          >
                            Save
                          </VBtn>
                        </div>
                      </VCardActions>
                    </VCard>
                  </VDialog>
                </div>
                <!-- Date Picker END-->
                <div>
                  <VSelect
                    v-model="admStatus"
                    :items="selectStatus"
                    :hide-details="true"
                    label="Status"
                    placeholder="Enrolled / Withdrawn"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VSelect
                    v-model="admClass"
                    :rules="[rules.required]"
                    :items="selectClass"
                    :hide-details="true"
                    label="Admission Class"
                    class="std_main_txt"
                    placeholder="LKG / 12"
                  />
                </div>
              </div>
            </VCard>
          </VForm>
        </div>
        <div class="span-row-2 order3">
          <VForm
            ref="form2"
            v-model="valid"
            lazy-validation
          >
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Admission Billing
              </p>
              <div class="std_content">
                <div>
                  <VTextField
                    v-model.number="payRegistration"
                    :value="payRegistration"
                    :hide-details="true"
                    :rules="[rules.required]"
                    type="number"
                    label="Registration"
                    placeholder="1000"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VTextField
                    v-model.number="payAnnual"
                    :value="payAnnual"
                    :hide-details="true"
                    :rules="[rules.required]"
                    type="number"
                    label="Annual"
                    placeholder="1000"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VTextField
                    v-model.number="payAdmission"
                    :value="payAdmission"
                    :hide-details="true"
                    :rules="[rules.required]"
                    type="number"
                    label="Admission"
                    placeholder="1000"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VTextField
                    v-model.number="payTuition"
                    :value="payTuition"
                    :hide-details="true"
                    :rules="[rules.required]"
                    type="number"
                    label="Tuition"
                    placeholder="1000"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VTextField
                    v-model.number="payDevelopment"
                    :value="payDevelopment"
                    :hide-details="true"
                    :rules="[rules.required]"
                    type="number"
                    label="Development"
                    placeholder="1000"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VTextField
                    v-model.number="payCaution"
                    :value="payCaution"
                    :hide-details="true"
                    :rules="[rules.required]"
                    type="number"
                    label="Caution"
                    placeholder="1000"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VTextField
                    :value="payTotal"
                    :hide-details="true"
                    readonly
                    label="Total"
                    placeholder="1000"
                    class="std_main_txt"
                  />
                </div>
                <div>
                  <VSelect
                    v-model="payMethod"
                    :items="selectPayment"
                    :hide-details="true"
                    item-text="text"
                    item-value="value"
                    label="Payment Method"
                    placeholder="Cash / Cheque"
                    class="std_main_txt"
                    return-object
                  />
                </div>
                <!-- if CHEQUE Payment -->
                <div v-if="payMethod.value === 'cheque'">
                  <VTextField
                    v-model="payCqNo"
                    :hide-details="true"
                    type="number"
                    label="Cheque No"
                    placeholder="1001"
                    class="std_main_txt"
                  />
                </div>
                <div v-if="payMethod.value === 'cheque'">
                  <VTextField
                    v-model="payCqbank"
                    :hide-details="true"
                    type="text"
                    label="Bank"
                    placeholder="AXIS"
                    class="std_main_txt"
                  />
                </div>
              </div>
            </VCard>
          </VForm>
        </div>
        <div class="span-col-2">
          <VForm
            ref="form3"
            v-model="valid"
            lazy-validation
          >
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Contact
              </p>
              <div class="std_address">
                <div class="span2">
                  <VTextField
                    v-model="stdPG"
                    :rules="[rules.required]"
                    :value="stdPG"
                    :hide-details="true"
                    label="Parent or Guardian"
                    placeholder="Parent / Guardian"
                    class="std_main_txt"
                  />
                </div>
                <div class="span2">
                  <VTextField
                    v-model="stdPNumber"
                    :hide-details="true"
                    :rules="[rules.required, rules.phoneNumber, rules.phoneNumberLimit]"
                    maxlength="10"
                    prefix="+91"
                    label="Phone No.(Primary)"
                    placeholder="0123456789"
                    class="std_main_txt"
                  />
                </div>
                <div class="span2">
                  <VTextField
                    v-model="stdSNumber"
                    :hide-details="true"
                    :rules="[rules.required, rules.phoneNumber, rules.phoneNumberLimit]"
                    maxlength="10"
                    prefix="+91"
                    label="Phone No.(Secondary)"
                    placeholder="0123456789"
                    class="std_main_txt"
                  />
                </div>
                <div class="span2">
                  <VTextField
                    v-model="stdEmail"
                    :hide-details="true"
                    :rules="[rules.required, rules.email]"
                    label="Email"
                    placeholder="hello@example.com"
                    class="std_main_txt"
                  />
                </div>
                <div class="span4">
                  <VTextarea
                    v-model="stdAddress"
                    :value="stdAddress"
                    :hide-details="true"
                    :rules="[rules.required]"
                    label="Address"
                    placeholder="38 Park Road, Barrackpore Cantonment, Barrackpore, Kolkata 700120"
                    class="std_main_txt"
                    rows="2"
                  />
                </div>
              </div>
            </VCard>
          </VForm>
        </div>
      </div>
    </section>
    <VDialog
      v-model="isSaving"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'processing'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Saving Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'success'"
        class="notification_card elevation-20"
      >
        <p>Saved</p>
        <span>Student Added Successfully</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'exists'"
        class="notification_card elevation-20"
      >
        <p>Warning</p>
        <span>Student Already Exists</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="orange"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <VSnackbar
      v-model="snack"
      color="secondary"
      :timeout="5000"
      :multi-line="true"
    >
      {{ snackTxt }}
      <VBtn
        color="primary"
        depressed
        style="width:100px;"
        round
        @click="snack = false"
      >
        Close
      </VBtn>
    </VSnackbar>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    snack: false,
    snackTxt: '',
    valid: true,
    isSaving: '',
    loadStatus: '',
    rules: {
      email:
        (val) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(val) || 'Invalid e-mail.';
        },
      phoneNumber:
        (val) => {
          const phNoPattern = /[0-9]/;
          return phNoPattern.test(val) || 'Invalid phone number.';
        },
      phoneNumberLimit: val => val.length === 10 || 'Invalid phone number.',
      required: (val) => {
        if (val == null) {
          return 'Required';
        }
        if (val === '') {
          return 'Required';
        }
        return true;
      },
    },
    admModal: false,
    dobModal: false,
    admDateDef: new Date().toISOString().substr(0, 10),
    stdDobDef: null,
    admNoInput: '',
    admNoFormat: '###-####',
    selectGender: [
      'Male',
      'Female',
    ],
    selectStatus: [
      'Enrolled',
      'Withdrawn',
    ],
    selectClass: [],
    selectCategory: [
      'General',
      'SC',
      'ST',
      'OBC',
    ],
    selectPayment: [
      { text: 'Cash', value: 'cash' },
      { text: 'Cheque', value: 'cheque' },
    ],
    // Admission Data Below
    // admNo = FLA-123-1819
    // admDate = 02-12-2018
    admStatus: 'Enrolled',
    admClass: '',
    stdName: '',
    stdGender: '',
    stdCategory: '',
    // stdDob: 01-01-2000 ,
    stdPG: '',
    stdEmail: 'contact@futurelaureates.com',
    stdPNumber: '',
    stdSNumber: '',
    stdAddress: '',
    // Admission Billing
    payRegistration: null,
    payAnnual: null,
    payAdmission: null,
    payTuition: null,
    payDevelopment: null,
    payCaution: null,
    //  payTotal: 15000
    payMethod: { text: 'Cash', value: 'cash' },
    payCqNo: '',
    payCqbank: '',
  }),
  computed: {
    admDate() {
      return moment(this.admDateDef).format('DD-MM-YYYY');
    },
    stdDob() {
      if (this.stdDobDef !== null) {
        return moment(this.stdDobDef).format('DD-MM-YYYY');
      }
      return '';
    },
    admNo() {
      const admInput = this.admNoInput;
      const admNoFormatted = `FLA-${admInput.substring(0, 3)}-${admInput.substring(3)}`;
      return admNoFormatted;
    },
    payTotal() {
      const payTotalSum = this.payRegistration + this.payAnnual + this.payAdmission
        + this.payTuition + this.payDevelopment + this.payCaution;
      return payTotalSum.toLocaleString('en-IN');
    },
  },
  watch: {
    dobModal(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
        });
      }
    },
  },
  async beforeMount() {
    axios.get(`${api}/settings/classes`).then((res) => {
      this.selectClass = res.data.data;
    });
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    save() {
      if (
        this.$refs.form.validate()
        && this.$refs.form1.validate()
        && this.$refs.form2.validate()
        && this.$refs.form3.validate()
      ) {
        const studentSchema = {
          admNo: this.admNo,
          basic: {
            name: this.stdName,
            gender: this.stdGender,
            category: this.stdCategory,
            dob: this.stdDob,
            class: this.admClass,
          },
          admission: {
            admNo: this.admNo,
            admDate: this.admDate,
            admStatus: this.admStatus,
            admClass: this.admClass,
          },
          contact: {
            parentName: this.stdPG,
            phonePrimary: this.stdPNumber,
            phoneSecondary: this.stdSNumber,
            email: this.stdEmail,
            address: this.stdAddress,
          },
          billing: {
            registration: this.payRegistration,
            annual: this.payAnnual,
            admission: this.payAdmission,
            tuition: this.payTuition,
            development: this.payDevelopment,
            caution: this.payCaution,
            total: this.payTotal,
            method: this.payMethod,
          },
          billingCheque: {
            cqNo: this.payCqNo,
            cqBank: this.payCqbank,
          },
        };
        this.loadStatus = 'processing';
        this.isSaving = true;
        axios.post(`${api}/student/add`, studentSchema)
          .then((res) => {
            if (res.data.status === 'success') {
              this.loadStatus = 'success';
              setTimeout(() => {
                this.isSaving = false;
                this.$router.push('/student');
              }, 2500);
            } else {
              this.loadStatus = 'exists';
              setTimeout(() => {
                this.isSaving = false;
              }, 2500);
            }
          }).catch((e) => {
            if (e.code === 'auth/network-request-failed') {
              this.loadStatus = 'netfail';
              setTimeout(() => {
                this.isSaving = false;
              }, 5000);
            } else {
              this.loadStatus = 'fail';
            }
          });
      } else {
        this.snack = true;
        this.snackTxt = 'Please Fill In All The Fields';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Student/new';
</style>
